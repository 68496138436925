export default function sum(data: any): number {
    const initialValue = 0
    const dataCopy: any = { ...data }
    for (const key in dataCopy) {
        if (dataCopy[key] === undefined) {
            delete dataCopy[key];
        }
    }
    console.log(dataCopy)
    const arrayFromDataValues: any = Object.values(dataCopy)
    const sumWithInitialValue = arrayFromDataValues.reduce((acc: number, curr: number) => Number(acc) + Number(curr), Number(initialValue))
    console.log(sumWithInitialValue, 'sumWithInitialValue')
    return sumWithInitialValue;
}