import { useAppDispatch } from "../../../../../../../app/hooks";
import {
  reset,
  setActiveOutlaysInnerStep,
  setVariousData,
  setTotalVarious,
  setTotal,
  setActiveStep,
} from "../../../../../calculatorSlice";
import { Grid, Typography, Button } from "@mui/material";

function Overdraft() {
  const dispatch = useAppDispatch();

  const handleBack = () => {
    dispatch(setVariousData([]));
    dispatch(setTotalVarious(0));
    dispatch(setTotal());
    dispatch(setActiveOutlaysInnerStep(0));
    dispatch(setActiveStep(0));
  };

  const handleReset = () => {
    dispatch(reset());
  };
  return (
    <Grid container>
      <Grid xs={12} item>
        <Typography variant="h3" component="h2">
          אויש, רגע
        </Typography>
        <br />
        <Typography variant="h5" component="h2">
          לפי הנתונים שהזנת כאן החשבון שלך יכנס למינוס
          <br />
          עוד לפני ההוצאות על דברים נוספים.
        </Typography>
        <br />
        <Typography variant="h5" component="h2">
          עוד לפני ששמת בצד כסף לחיסכון <br /> ועוד לפני שהוצאת על הוצאות משתנות
        </Typography>
        <br />
        <Typography variant="h5" component="h2">
          כדאי לחזור אחרוה ולבדוק את הסכומים,
          <br /> אם זה המצב חושב לראות איך ניתן להגדיל <br />
          את ההכנסות או לצמצם את ההוצאות
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <br />
        <br />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          <Button
            onClick={handleReset}
            variant="contained"
            color="primary"
            fullwidth
          >
            <Typography variant="h4" component="h3">
              שנשנה את החישוב?
              <br />
              <span style={{ fontSize: "1rem" }}>
                לחיצה כאן תאפס לך את כל הנתונים
              </span>
            </Typography>
          </Button>
          <Button
            sx={[
              {
                background: "#fafafa",
                color: "black",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                },
              },
            ]}
            onClick={handleBack}
            variant="contained"
            color="primary"
          >
            <Typography variant="h4" component="h3">
              אחורה
              <br />
              <span style={{ fontSize: "1rem" }}>
                לחיצה כאן תחזיר אותך אחרוה
              </span>
            </Typography>
          </Button>
        </div>
        <br />
        <br />
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
}

export default Overdraft;
