import { Grid, TextField, Button, Typography, Grow } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/hooks";
import {
  selectCalculatorState,
  setEducationData,
  setOutlaysInnerAllowNextStep,
  setTotal,
  setActiveOutlaysInnerStep,
  setTotoalEducation,
  setActiveStep,
} from "../../../../../../calculatorSlice";
import { IEducationData } from "../../../../../../../../lib/interfaces/IFixedOutlay";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import sum from "../../../../../../../../lib/utils/sum";
import { NumberFormatCustom } from "../../../../../FormattedInputs";
import ArrowLeft from "../../../../../../assets/arrow-left.svg";
import ArrowRight from "../../../../../../assets/arrow-right.svg";
import { CustomTextFiled } from "../../../../../CustomTextFiled";

function EducationForm() {
  const dispatch = useAppDispatch();
  const CalculatorState = useAppSelector(selectCalculatorState);
  const { educationData } = CalculatorState;
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IEducationData>({
    defaultValues: educationData,
  });
  const onSubmit: SubmitHandler<IEducationData> = (data) => {
    let temp;

    delete data.educationTotal;
    if (data.tuition) {
      temp = data?.tuition / 12 || 0;
      const tempo = Number(temp).toFixed(2);
      delete data.tuition;
      data.tuition = Number(tempo);
    }

    const sumWithInitialValue = sum(data);
    dispatch(setEducationData(data));
    dispatch(setTotoalEducation(sumWithInitialValue));
    dispatch(setTotal());
    dispatch(setOutlaysInnerAllowNextStep(6));
    dispatch(setActiveOutlaysInnerStep(5));
  };
  const handleBackClick = () => {
    if (CalculatorState.allowedSteps > 0) {
      if (
        CalculatorState.outlaysInnerAllowedSteps >= 0 &&
        CalculatorState.outlaysInnerActiveStep === 0
      ) {
        dispatch(setActiveStep(0));
      }
      if (
        CalculatorState.outlaysInnerAllowedSteps > 0 &&
        CalculatorState.outlaysInnerActiveStep > 0
      ) {
        dispatch(
          setActiveOutlaysInnerStep(CalculatorState.outlaysInnerActiveStep - 1)
        );
      }
    }
  };

  return (
    <Grid
      sx={{ textAlign: "center", display: "grid", placeItems: "center" }}
      container
    >
      <Grid item>
        <br />
        <Grow timeout={{ enter: 900, exit: 100 }} in={true}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  width: "100%",
                  fontSize: "1.75rem",

                  padding: 0,
                }}
              >
                לימודים
              </h3>
            </div>
            <br />
            <Controller
              name="tuition"
              control={control}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  error={!!errors.tuition}
                  helperText={errors.tuition && "חובה להזין ערך"}
                  sx={{
                    marginBottom: "1rem",
                    width: "90%",
                    textAlign: "center",
                    margin: "0.25rem auto",
                  }}
                  id="filled-basic"
                  label="שכר לימוד - עלות שנתית"
                  variant="filled"
                />
              )}
            />
            <Controller
              name="extra"
              control={control}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  error={!!errors.extra}
                  helperText={errors.extra && "חובה להזין ערך"}
                  sx={{
                    marginBottom: "1rem",
                    width: "90%",
                    textAlign: "center",
                    margin: "0.25rem auto",
                  }}
                  id="filled-basic"
                  label="התחייבויות נוספות לתשלום"
                  variant="filled"
                />
              )}
            />

            <br />
            <div
              style={{
                display: "grid",
                placeItems: "center",
                alignItems: "center",
              }}
            >
              <br />
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  gridTemplateColumns: "auto auto",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <button onClick={handleBackClick} className="back">
                  <img
                    src={ArrowRight}
                    alt="arrow"
                    style={{ marginBottom: "0.25rem" }}
                  />
                  <Typography
                    sx={{ fontSize: "1.2rem", color: "black" }}
                    display="block"
                    gutterBottom
                  >
                    הקודם
                  </Typography>
                </button>
                <button className="btn next" type="submit">
                  <Typography
                    sx={{ fontSize: "1.2rem" }}
                    display="block"
                    gutterBottom
                  >
                    הבא
                  </Typography>
                  <img
                    src={ArrowLeft}
                    alt="arrow"
                    style={{ marginBottom: "0.25rem" }}
                  />
                </button>
              </div>
            </div>
          </form>
        </Grow>
        <br />
      </Grid>
    </Grid>
  );
}

export default EducationForm;
