import * as React from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  selectCalculatorState,
  setActiveStep,
  setActiveOutlaysInnerStep,
  setFinished,
  setStarted,
} from "../calculatorSlice";
import { Grid, Box, Typography, Card, Paper, Button } from "@mui/material";

import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { useForm } from "react-hook-form";

import NumberFormat from "react-number-format";

function ActionButtons() {
  const dispatch = useAppDispatch();
  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "0 auto",
        marginTop: "2rem",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "1rem",
      }}
    >
      <button
        className="btn-no-icon "
        onClick={() => {
          dispatch(setStarted(false));
          dispatch(setActiveStep(0));
          dispatch(setFinished(false));
        }}
      >
        חזרה
      </button>
      <button
        style={{ background: "#fafafa", color: "black" }}
        className="btn-no-icon "
        onClick={() => {
          dispatch(setStarted(false));
          dispatch(setActiveStep(0));
          dispatch(setFinished(false));
        }}
      >
        לסביבת מומנטום
      </button>
    </div>
  );
}

export default ActionButtons;
