import { Grid, TextField, Button, Typography, Grow } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/hooks";
import {
  selectCalculatorState,
  setHousingData,
  setOutlaysInnerAllowNextStep,
  setActiveOutlaysInnerStep,
  setTotalHousing,
  setTotal,
  setActiveStep,
} from "../../../../../../calculatorSlice";
import { IHousingData } from "../../../../../../../../lib/interfaces/IFixedOutlay";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import sum from "../../../../../../../../lib/utils/sum";
import { NumberFormatCustom } from "../../../../../FormattedInputs";
import { CustomTextFiled } from "../../../../../CustomTextFiled";
import ArrowLeft from "../../../../../../assets/arrow-left.svg";
import ArrowRight from "../../../../../../assets/arrow-right.svg";

function Housing() {
  const dispatch = useAppDispatch();
  const CalculatorState = useAppSelector(selectCalculatorState);

  const handleBackClick = () => {
    if (CalculatorState.allowedSteps > 0) {
      if (
        CalculatorState.outlaysInnerAllowedSteps >= 0 &&
        CalculatorState.outlaysInnerActiveStep === 0
      ) {
        dispatch(setActiveStep(0));
      }
      if (
        CalculatorState.outlaysInnerAllowedSteps > 0 &&
        CalculatorState.outlaysInnerActiveStep > 0
      ) {
        dispatch(
          setActiveOutlaysInnerStep(CalculatorState.outlaysInnerActiveStep - 1)
        );
      }
    }
  };

  const { housingData } = useAppSelector(selectCalculatorState);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IHousingData>({
    defaultValues: housingData,
  });
  const onSubmit: SubmitHandler<IHousingData> = (data) => {
    let temp;
    delete data.housingTotal;
    if (data.propertyTax) {
      temp = data?.propertyTax / 2 || 0;
      const tempo = Number(temp).toFixed(2);
      delete data.propertyTax;
      data.propertyTax = Number(tempo);
    }

    if (data.electricity) {
      temp = data?.electricity / 2 || 0;
      delete data.electricity;
      const tempo = Number(temp).toFixed(2);
      data.electricity = Number(tempo);
    }
    if (data.water) {
      temp = data?.water / 2 || 0;
      delete data.water;
      const tempo = Number(temp).toFixed(2);
      data.water = Number(tempo);
    }
    const sumWithInitialValue = sum(data);
    console.log(data);
    dispatch(setHousingData(data));
    dispatch(setTotalHousing(sumWithInitialValue));
    dispatch(setTotal());
    dispatch(setOutlaysInnerAllowNextStep(1));
    dispatch(setActiveOutlaysInnerStep(1));
  };
  return (
    <Grid
      sx={{ textAlign: "center", display: "grid", placeItems: "center" }}
      container
    >
      <Grid item>
        <Grow timeout={{ enter: 900, exit: 100 }} in={true}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "700",
                width: "100%",
                fontSize: "1.75rem",
                marginBottom: "0.425rem",
                padding: 0,
                marginTop: "0.325rem",
              }}
            >
              דיור
            </h3>
            <Controller
              name="rent"
              control={control}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  error={!!errors.rent}
                  helperText={errors.rent && "חובה להזין ערך"}
                  placeholder="0"
                  sx={{
                    marginBottom: "1rem",
                    width: "90%",
                    textAlign: "center",
                    margin: "0.25rem auto",
                  }}
                  id="filled-basic"
                  label="שכר דירה"
                  variant="filled"
                />
              )}
            />
            <Controller
              name="propertyTax"
              control={control}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  error={!!errors.rent}
                  helperText={errors.rent && "חובה להזין ערך"}
                  sx={{
                    marginBottom: "1rem",
                    width: "90%",
                    textAlign: "center",
                    margin: "0.25rem auto",
                  }}
                  id="filled-basic"
                  label=" ארנונה חשבון דו חודשי"
                  variant="filled"
                />
              )}
            />
            <Controller
              name="electricity"
              control={control}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  error={!!errors.rent}
                  helperText={errors.rent && "חובה להזין ערך"}
                  sx={{
                    marginBottom: "1rem",
                    width: "90%",
                    textAlign: "center",
                    margin: "0.25rem auto",
                  }}
                  id="filled-basic"
                  label="חשמל חשבון דו חודש"
                  variant="filled"
                />
              )}
            />
            <Controller
              name="water"
              control={control}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  error={!!errors.water}
                  helperText={errors.water && "חובה להזין ערך"}
                  sx={{
                    marginBottom: "1rem",
                    width: "90%",
                    textAlign: "center",
                    margin: "0.25rem auto",
                  }}
                  id="filled-basic"
                  label="מים חשבון דו חודשי"
                  variant="filled"
                />
              )}
            />
            <Controller
              name="houseCommittee"
              control={control}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  error={!!errors.rent}
                  helperText={errors.rent && "חובה להזין ערך"}
                  sx={{
                    marginBottom: "1rem",
                    width: "90%",
                    textAlign: "center",
                    margin: "0.25rem auto",
                  }}
                  id="filled-basic"
                  label="ועד בית תשלום חודשי"
                  variant="filled"
                />
              )}
            />
            <br />
            <br />

            <div
              style={{
                display: "grid",
                placeItems: "center",
                alignItems: "center",
                gridTemplateColumns: "auto auto",
                alignContent: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <button onClick={handleBackClick} className="back">
                <img
                  src={ArrowRight}
                  alt="arrow"
                  style={{ marginBottom: "0.25rem" }}
                />
                <Typography
                  sx={{ fontSize: "1.2rem", color: "black" }}
                  display="block"
                  gutterBottom
                >
                  הקודם
                </Typography>
              </button>
              <button className="btn next" type="submit">
                <Typography
                  sx={{ fontSize: "1.2rem" }}
                  display="block"
                  gutterBottom
                >
                  הבא
                </Typography>
                <img
                  src={ArrowLeft}
                  alt="arrow"
                  style={{ marginBottom: "0.25rem" }}
                />
              </button>
            </div>
          </form>
        </Grow>
        <br />
      </Grid>
    </Grid>
  );
}

export default Housing;
