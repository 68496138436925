import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
    IHousingData,
    ITransportData,
    ICommunicationData,
    IHealthData,
    IEducationData,
    ICalculatorState
} from '../../lib/interfaces/IFixedOutlay';

const initialState: ICalculatorState = {
    activeStep: 0,
    allowedSteps: 0,
    outlaysInnerActiveStep: 0,
    outlaysInnerAllowedSteps: 0,
    summertInnerActiveStep: 'savings',
    monthlyIncome: undefined,
    housingData: {
        rent: undefined,
        propertyTax: undefined,
        electricity: undefined,
        water: undefined,
        houseCommittee: undefined,
        housingTotal: 0
    },
    transportData: {
        carInsurance: undefined,
        carTest: undefined,
        carMaintenance: undefined,
        publicTransport: undefined,
        transportTotal: 0,
        isDivided: false,
    },
    communicationData: {
        cellphone: undefined,
        cellphoneSubscribers: undefined,
        communicationTotal: 0,
    },
    healthData: {
        hmo: undefined,
        insurance: undefined,
        healthTotal: 0,
    },
    educationData: {
        tuition: undefined,
        extra: undefined,
        educationTotal: 0,
    },
    VariousOutlayData: {
        items: [],
        variousTotal: 0
    },
    forceContinue: false,
    total: 0,
    toSpear: 0,
    savings: 0,
    finished: false,
    started: false,
};

export const calculatorSlice = createSlice({
    name: 'calculator',
    initialState,
    reducers: {
        allowNextStep: (state: { allowedSteps: number }, action: { payload: number; }) => {
            state.allowedSteps = action.payload
        },
        setActiveStep: (state: { activeStep: number; }, action: { payload: number; }) => {
            state.activeStep = action.payload;
        },
        setFinished: (state: { finished: boolean; }, action: { payload: boolean; }) => {
            state.finished = action.payload;
        },
        setMonthlyIncome: (state: { monthlyIncome: number | undefined; }, action: { payload: number; }) => {
            state.monthlyIncome = action.payload;
        },
        setHousingData: (state: { housingData: IHousingData; }, action: { payload: IHousingData; }) => {
            state.housingData = action.payload;
        },
        setTotalHousing: (state: { housingData: IHousingData; }, action: { payload: number; }) => {
            state.housingData.housingTotal = action.payload; 
        },
        setTransportData: (state: { transportData: ITransportData; }, action: { payload: ITransportData; }) => {
            state.transportData = action.payload;
        },
        setTotalTransport: (state: { transportData: ITransportData; }, action: { payload: number; }) => {
            state.transportData.transportTotal = action.payload;
        },
        setCommunicationData: (state: { communicationData: ICommunicationData; }, action: { payload: ICommunicationData; }) => {
            state.communicationData = action.payload;
        },
        setTotoalCommunication: (state: { communicationData: ICommunicationData; }, action: { payload: number; }) => {
            state.communicationData.communicationTotal = action.payload;
        },
        setHealthData: (state: { healthData: IHealthData; }, action: { payload: IHealthData; }) => {
            state.healthData = action.payload;
        },
        setTotalHealth: (state: { healthData: IHealthData; }, action: { payload: number; }) => {
            state.healthData.healthTotal = action.payload;
        },
        setEducationData: (state: { educationData: IEducationData; }, action: { payload: IEducationData; }) => {
            state.educationData = action.payload;
        },
        setTotoalEducation: (state: { educationData: IEducationData; }, action: { payload: number; }) => {
            state.educationData.educationTotal = action.payload;
        },
        setVariousData: (state: { VariousOutlayData: any; }, action: { payload: any; }) => {
            state.VariousOutlayData.items = action.payload;
        },
        setTotalVarious: (state: { VariousOutlayData: any; }, action: { payload: number; }) => {
            state.VariousOutlayData.variousTotal = action.payload;
        },
        setOutlaysInnerAllowNextStep: (state: { outlaysInnerAllowedSteps: number }, action: { payload: number; }) => {
            state.outlaysInnerAllowedSteps = action.payload
        },
        setActiveOutlaysInnerStep: (state: { outlaysInnerActiveStep: number; }, action: { payload: number; }) => {
            state.outlaysInnerActiveStep = action.payload;
        },
        setSavings: (state: { savings: number | undefined; }, action: { payload: number; }) => {
            state.savings = action.payload;
        },
        setToSpear: (state: { toSpear: number | undefined; }, action: { payload: number; }) => {
            state.toSpear = action.payload;
        },
        setForceContinue: (state: { forceContinue: boolean; }, action: { payload: boolean; }) => {
            state.forceContinue = action.payload;
        },
        setTotal: (state) => {
            const calcTotalTransform = () => {
                // carMaintenance || carTest || carInsurance
                let carInsurance =
                  state.transportData?.carInsurance !== undefined
                    ? state.transportData?.carInsurance
                    : 0;
                let carMaintenance =
                  state.transportData?.carMaintenance !== undefined
                    ? state.transportData?.carMaintenance
                    : 0;
                    let carTest =
                    state.transportData?.carTest !== undefined
                      ? state.transportData?.carTest
                      : 0;
                      let publicTransport =
                      state.transportData?.publicTransport !== undefined
                        ? state.transportData?.publicTransport
                        : 0;
                      let total = ((carInsurance  / 12 + carMaintenance / 12 + carTest / 12) )  + Number(publicTransport)
                      console.log(carInsurance, carMaintenance , carTest , total, "total transport form slice ");
                      console.log(carInsurance, "carInsurance transport form slice ");

                      return total;
            }
            const total = Number(state.VariousOutlayData.variousTotal) + Number(state.housingData.housingTotal) + Number(calcTotalTransform()) + Number(state.communicationData.communicationTotal) + Number(state.healthData.healthTotal) + Number(state.educationData.educationTotal);
            console.log(total);
            state.total = total ? total : 0;
            const diff = Number(state.monthlyIncome) - Number(state.total);
            state.toSpear = diff ? diff : 0;
        },
        setStarted: (state: { started: boolean; }, action: { payload: boolean; }) => {
            state.started = action.payload;
        },
        reset: () => initialState
    }
});

export const {
    setHousingData,
    allowNextStep,
    setActiveStep,
    setMonthlyIncome,
    setOutlaysInnerAllowNextStep,
    setActiveOutlaysInnerStep,
    setTransportData,
    setCommunicationData,
    setHealthData,
    setEducationData,
    setVariousData,
    setTotalHousing,
    setTotalTransport,
    setTotoalCommunication,
    setTotalHealth,
    setTotoalEducation,
    setTotal,
    setTotalVarious,
    setSavings,
    setToSpear,
    setForceContinue,
    reset,
    setFinished,
    setStarted
} = calculatorSlice.actions;

export const selectActiveStep = (state: RootState) => state.calculator.activeStep;
export const calcStated = (state: RootState) => state.calculator.finished;
export const selectAllowedSteps = (state: RootState) => state.calculator.allowedSteps;
export const selectMonthlyIncome = (state: RootState) => state.calculator.monthlyIncome;
export const selectHousingData = (state: RootState) => state.calculator.housingData;
export const selectCalculatorState = (state: RootState) => state.calculator;

export default calculatorSlice.reducer;