import { useAppSelector, useAppDispatch } from "../../../../../../app/hooks";
import {
  setActiveStep,
  setMonthlyIncome,
  allowNextStep,
  selectActiveStep,
  selectMonthlyIncome,
} from "../../../../calculatorSlice";
import { Grid, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/material/styles";
import Spaceman from "../../../OpenScreen/assets/spaceman.png";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import { IMonthlyIncome } from "../../../../../../lib/interfaces/IMonthlyIncome";
import { NumberFormatCustom } from "../../../FormattedInputs";
import { CustomTextFiled } from "../../../CustomTextFiled";
import Calc from "../../../../assets/calc.svg";
import styles from "../../../OpenScreen/OpenScreen.module.css";
import WaveLogo from "../../../../../../wave_logo.svg";
function IncomeStep() {
  const activeStep = useAppSelector(selectActiveStep);
  const monthlyIncome = useAppSelector(selectMonthlyIncome);

  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IMonthlyIncome>();
  const onSubmit: SubmitHandler<IMonthlyIncome> = (data) => {
    console.log(data);
    dispatch(setMonthlyIncome(Number(data.monthlyIncome)));
    dispatch(allowNextStep(activeStep + 1));
    dispatch(setActiveStep(activeStep + 1));
  };

  return (
    <>
      <Grid
        sx={{ textAlign: "center", display: "grid", placeItems: "center" }}
        container
      >
        <>
          <br />
          <Typography variant="h4">הכנסות</Typography>

          <Typography
            gutterBottom
            sx={{ textAlign: "center", placeItems: "center", padding: "1rem" }}
            color="text.secondary"
            variant="h6"
          >
            <strong>כמה כסף נכנס לחשבון שלך נטו בכל חודש?</strong>
            <br />
            <br />
            משכורת / מלגה / מענקים אחרים (בלי לחץ, אפשר לכתוב בערך...)
          </Typography>
        </>
        <>
          <form
            style={{ width: "100%", justifyContent: "inherit" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <br />
            <Controller
              defaultValue={monthlyIncome}
              name="monthlyIncome"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextFiled
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                  }}
                  {...field}
                  disableUnderline={true}
                  error={!!errors.monthlyIncome}
                  helperText={errors.monthlyIncome && "חובה להזין ערך"}
                  sx={{
                    marginBottom: "1rem",
                    width: "100%",
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                  label="כאן לכתוב את הסכום במספרים"
                  variant="filled"
                />
              )}
            />
            <br />
            <br />
            <br />

            <Grid item>
              <button className="btn" type="submit">
                <img src={Calc} alt="arrow" />
                <Typography>התחל לחשב</Typography>
              </button>
            </Grid>
          </form>
        </>
        <br />
        <Grid item></Grid>
      </Grid>
    </>
  );
}

export default IncomeStep;
