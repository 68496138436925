import { useAppDispatch } from "../../../../../../../app/hooks";
import { setForceContinue, reset } from "../../../../../calculatorSlice";
import { FC } from "react";
import { Grid, Typography, Button } from "@mui/material";

const OverdraftProcess: FC<any> = () => {
  const dispatch = useAppDispatch();
  const handleForceContinue = () => {
    dispatch(setForceContinue(true));
  };
  const handleReset = () => {
    dispatch(setForceContinue(false));
    dispatch(reset());
  };
  return (
    <Grid container>
      <div style={{ margin: "0 auto", padding: "1rem" }}>
        <Grid xs={12} item sx={{ padding: "1rem" }}>
          <Typography variant="h4" component="h2">
            חשוב לשים לב!
          </Typography>
          <br />
          <Typography variant="h5" component="h2">
            <strong>החשבון שלך כבר עכשיו במינוס</strong>
          </Typography>
          <br />
          <Typography variant="h5" component="h2">
            עוד לפני ששמת בצד כסף לחיסכון <br /> ועוד לפני שהוצאת על הוצאות
            משתנות
          </Typography>
          <br />
          <Typography variant="h5" component="h2">
            כדאי לחזור אחורה ולבדוק את הסכומים,
            <br /> אם זה המצב חשוב לראות איך ניתן להגדיל <br />
            את ההכנסות או לצמצם את ההוצאות
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
          <br />
          <Button
            onClick={handleReset}
            sx={{ width: "50%" }}
            variant="contained"
            color="primary"
          >
            <Typography
              sx={{ lineHeight: "1.25rem" }}
              variant="button-text"
              component="h3"
            >
              לא מרוצה מהחישוב?
              <br />
              אפשר לחזור ולשנות
            </Typography>
          </Button>
          <br />
          <br />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleForceContinue}
            sx={[
              {
                width: "50%",
                backgroundColor: "white",
                color: "#556cd6",
                lineHeight: "1.25rem",
              },
            ]}
            variant="contained"
            color="primary"
          >
            <Typography variant="button-text" component="h3">
              תן לי להמשיך
              <br />
              בכל זאת
            </Typography>
          </Button>
        </Grid>
      </div>
    </Grid>
  );
};

export default OverdraftProcess;
