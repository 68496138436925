import * as React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../../../../app/hooks";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  selectCalculatorState,
  setActiveOutlaysInnerStep,
  setFinished,
} from "../../../../../../calculatorSlice";
import theme from "../../../../../../../../app/theme";
import { StepIconProps } from "@mui/material/StepIcon";
import HouseIcon from "@mui/icons-material/House";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import RouterIcon from "@mui/icons-material/Router";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import SchoolIcon from "@mui/icons-material/School";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grow from "@mui/material/Grow";

const steps = [
  { number: 1, title: "דיור" },
  { number: 2, title: "רכב" },
  { number: 3, title: "תקשורת" },
  { number: 4, title: "בריאות" },
  { number: 5, title: "לימודים" },
  { number: 6, title: "שונות" },
];

function OutlaysInnerStepper() {
  const dispatch = useAppDispatch();
  const { outlaysInnerActiveStep, outlaysInnerAllowedSteps } = useAppSelector(
    selectCalculatorState
  );
  const QontoConnector = styled(StepConnector)(({ theme: any }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: "14px",
      left: "calc(-50% + 8px)",
      right: "calc(50% + 8px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor:
          "linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 176) 120%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "rgb(0, 127, 255)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    fontSize: 12,
    width: "28px",
    height: "28px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      transform: "scale(1)",
      animation: "pulse-black 2s infinite",
      backgroundImage:
        " linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178) 120%);",
      boxShadow:
        "linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178) 120%);",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "  linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178) 120%);",
    }),
  }));
  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const icons: { [index: string]: React.ReactElement } = {
      1: <HouseIcon />,
      2: <DirectionsCarFilledIcon />,
      3: <RouterIcon />,
      4: <HealthAndSafetyIcon />,
      5: <SchoolIcon />,
      6: <AddCircleIcon />,
    };
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {active === true ? (
          <Grow timeout={{ enter: 900, exit: 100 }} in={true}>
            {icons[String(props.icon)]}
          </Grow>
        ) : (
          icons[String(props.icon)]
        )}
      </ColorlibStepIconRoot>
    );
  }
  return (
    <div
      style={{
        margin: "0 auto",
        display: "grid",
        justifyContent: "center",
      }}
    >
      <Stepper
        sx={{ maxWidth: "600px" }}
        alternativeLabel
        activeStep={outlaysInnerActiveStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step
            onClick={(e) => {
              console.log(label);
              if (
                outlaysInnerAllowedSteps > label.number ||
                outlaysInnerAllowedSteps === label.number
              ) {
                dispatch(setActiveOutlaysInnerStep(label.number - 1));
                dispatch(setFinished(false));
              }
            }}
            key={label.number}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {label.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default OutlaysInnerStepper;
