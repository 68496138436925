import React from "react";
import WaveLogo from "../../../wave_logo.svg";

function Footer() {
  return (
    <footer
      style={{
        width: "100%",
        transform: "translate(0px,20%)",
      }}
    >
      <p>
        מחשבון זה אינו מהווה תחליף לייעוץ פיננסי זה או אחר והינו באחריות
        המשתמשים בלבד!
      </p>
      <img
        style={{ marginTop: "3rem", marginBottom: "3rem" }}
        src={WaveLogo}
        alt="wave logo"
      />
    </footer>
  );
}

export default Footer;
