import React from "react";

import { Calculator } from "./features/calculator/Calculator";
import "./App.css";
import { Container } from "@mui/material";
import WaveLogo from "./wave_logo.svg";

function App() {
  return (
    <Container maxWidth="xl" disableGutters={true}>
      <div className="main_layout">
        <Calculator />
      </div>
    </Container>
  );
}

export default App;
