import * as React from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../app/hooks";
import {
  selectCalculatorState,
  setActiveStep,
  setActiveOutlaysInnerStep,
  setFinished,
} from "../../../../../calculatorSlice";
import { Grid, Box, Typography, Card, Paper, Button } from "@mui/material";

import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { useForm } from "react-hook-form";
import Tips from "../../../../Tips";
import NumberFormat from "react-number-format";

function Finished() {
  const dispatch = useAppDispatch();
  const [confetti, setConfetti] = React.useState(true);
  const CalculatorState = useAppSelector(selectCalculatorState);
  const { savings, toSpear } = CalculatorState;

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<any>();
  React.useEffect(() => {
    setTimeout(() => {
      setConfetti(false);
    }, 5000);
  }, []);
  const handleBackClick = () => {
    if (CalculatorState.allowedSteps === 2) {
      if (CalculatorState.activeStep === 2) {
        dispatch(setActiveOutlaysInnerStep(0));
        dispatch(setActiveStep(CalculatorState.activeStep - 1));
      }
    }
  };
  const def = (toSpear: number, savings: number) => {
    if (savings === 0 || savings === undefined) {
      return toSpear;
    }
    return (toSpear - savings).toFixed(2);
  };
  const defPerDay = (toSpear: number, savings: number) =>
    ((toSpear - savings) / 30).toFixed(2);
  const { width, height } = useWindowSize();
  React.useEffect(() => {
    return () => {};
  }, [dispatch]);

  return (
    <Grid
      sx={{ textAlign: "center", display: "grid", placeItems: "center" }}
      container
    >
      {confetti && (
        <Confetti width={width} height={height} tweenDuration={3000} />
      )}

      <div className="outlay_back_button"></div>
      <br />
      <br />
      <Card sx={{ padding: "1rem" }}>
        <Grid sm={12} item>
          <Typography variant="h4" component="h1" gutterBottom>
            אוקי
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom>
            סחתיין על הסבלנות
          </Typography>
        </Grid>
        <Grid sm={12} item></Grid>
        <Grid item>
          <Box></Box>
        </Grid>
        <Grid item>
          <Typography
            sx={{ padding: "1rem" }}
            variant="h6"
            component="h3"
            gutterBottom
          >
            אז ככה זה הסכום שנשאר לך להוציא כל חודש{" "}
            <Paper
              sx={{ padding: "0.75rem", margin: "0 1.75rem" }}
              elevation={5}
            >
              <NumberFormat
                decimalScale={2}
                value={def(Number(toSpear), Number(savings))}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₪"}
              />
            </Paper>{" "}
          </Typography>
          <Typography
            sx={{ padding: "1rem" }}
            variant="h6"
            component="h3"
            gutterBottom
          >
            המשמעות היא שמותר לך להוציא כל יום בערך...{" "}
            <Paper
              sx={{ padding: "0.75rem", margin: "0 1.75rem" }}
              elevation={5}
            >
              <NumberFormat
                decimalScale={2}
                value={defPerDay(Number(toSpear), Number(savings))}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₪"}
              />
            </Paper>{" "}
          </Typography>
        </Grid>
      </Card>

      <Grid item></Grid>
    </Grid>
  );
}

export default Finished;
