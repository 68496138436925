import { Button } from "@mui/material";
import { useAppDispatch } from "../../../../app/hooks";
import { setStarted } from "../../calculatorSlice";
import styles from "./OpenScreen.module.css";
import Spaceman from "./assets/spaceman.png";
import WelcomeImage from "./assets/welcome.png";
import Logo from "./assets/logo_lev.svg";
import StepperComponent from "../Stepper/StepperComponent";
import { styled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button";
import CalculatorSvg from "./assets/calculator.svg";
import PlaySvg from "./assets/video-circle.svg";
import { Typography } from "@mui/material";
import WaveLogo from "../../../../wave_logo.svg";
const PlayButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: "50px",
  color: "#000000",
  fontWeight: "300",
  fontSize: "1rem",
  display: "grid",

  gridTemplateColumns: "auto 1fr",
  background: "#ECF8FC",
  "&:hover": {},
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
}));

const StartIcon = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: "50px",
  fontSize: "1rem",
  fontWeight: "300",
  background: "#26ABE2",
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  placeItems: "center",
  "&:hover": {
    background: "#26ABE9",
  },
}));

function OpenScreen() {
  const dispatch = useAppDispatch();
  const handleStart = () => {
    dispatch(setStarted(true));
  };
  return (
    <div className={styles.welcome_main_grid}>
      <div className={styles.welcome_image_container}>
        <img
          className={styles.welcome_image}
          src={WelcomeImage}
          alt="welcome_image"
        />
      </div>
      <section className={styles.welcome}>
        <img alt="logo" className={styles.logo} src={Logo} />
        <h1 className={styles.welcome_text_highlight}>
          <strong>ברוכים הבאים למחשבון התקציב שלך!</strong>
        </h1>

        <h3 className={styles.welcome_text}>
          אז למה בעצם חשוב לחשב את ההכנסות וההוצאות שלך?
          <br />
          בעיקר כדי לדעת מהו הסכום המקסימלי שכדאי לך להוציא בכל חודש
        </h3>

        <div className={styles.stepper_container}>
          <h3 className={styles.welcome_text_sub}>
            <strong>
              ב3 צעדים פשוטים נעזור לך לחשב את התקציב שלך בגובה העיניים.
            </strong>
          </h3>
          <div style={{ margin: "0 auto" }}>
            <br />
            <StepperComponent />
          </div>
        </div>
        <br />
        <div className={styles.welcome_action}>
          <a
            style={{
              placeItems: "center",
              textDecoration: "none",
              width: "100%",
            }}
            target="_blank"
            href="https://www.youtube.com/watch?v=v-TYHioFsjM"
            rel="noreferrer"
          >
            <PlayButton fullWidth={true} variant="outlined" color="primary">
              <img
                style={{
                  marginRight: "1rem",
                  width: "45px",
                  transform: "translate(22px,0%)",
                }}
                alt="calculator btn "
                src={PlaySvg}
              />
              <div style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                לצפייה בסרטון ההסבר{" "}
              </div>
            </PlayButton>
          </a>
          <StartIcon
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={handleStart}
          >
            <img
              style={{
                marginRight: "1rem",
                width: "45px",
                transform: "translate(22px,0%)",
              }}
              alt="calculator btn "
              src={CalculatorSvg}
            />
            <div style={{ fontWeight: "300", fontSize: "1.4rem" }}>
              בוא נתחיל לחשב{" "}
            </div>
          </StartIcon>
        </div>
        <br />
        <br />
        <br />

        <footer
          style={{
            width: "100%",
            transform: "translate(0px,20%)",
          }}
        >
          <p>
            מחשבון זה אינו מהווה תחליף לייעוץ פיננסי זה או אחר והינו באחריות
            המשתמשים בלבד!
          </p>
          <img
            style={{ marginTop: "3rem", marginBottom: "3rem" }}
            src={WaveLogo}
            alt="wave logo"
          />
        </footer>
      </section>
      <div className={styles.spaceman}>
        <div className="animate__animated animate__shakeY  animate__infinite	infinite">
          <img className={styles.spaceman_img} src={Spaceman} alt="spaceman" />
        </div>
      </div>
    </div>
  );
}

export default OpenScreen;
