import { useAppSelector } from "../../../../../../app/hooks";
import { selectCalculatorState } from "../../../../calculatorSlice";
import { Grid } from "@mui/material";
import Savings from "./components/Savings";
import Overdraft from "./components/Overdraft";
import WaveLogo from "../../../../../../wave_logo.svg";

function SummaryStep() {
  const { forceContinue, toSpear } = useAppSelector(selectCalculatorState);
  return (
    <Grid
      sx={{ textAlign: "center", display: "grid", placeItems: "center" }}
      container
    >
      {(Number(toSpear) as number) <= 0 && forceContinue === false && (
        <Overdraft />
      )}
      {(Number(toSpear) as number) < 0 && forceContinue === true && (
        <Overdraft />
      )}
      {(Number(toSpear) as number) > 0 && <Savings />}
      <footer
        style={{
          width: "100%",
          transform: "translate(0px,20%)",
        }}
      >
        <p>
          מחשבון זה אינו מהווה תחליף לייעוץ פיננסי זה או אחר והינו באחריות
          המשתמשים בלבד!
        </p>
        <img
          style={{ marginTop: "3rem", marginBottom: "3rem" }}
          src={WaveLogo}
          alt="wave logo"
        />
      </footer>
    </Grid>
  );
}

export default SummaryStep;
