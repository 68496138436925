import * as React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Button,
  Paper,
  Typography,
  Grow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/hooks";
import {
  selectCalculatorState,
  setTotal,
  allowNextStep,
  setActiveStep,
  setTotalVarious,
  setVariousData,
  setActiveOutlaysInnerStep,
} from "../../../../../../calculatorSlice";
import styles from "./VariousOutlayForm.module.css";
import { NumberFormatCustom } from "../../../../../FormattedInputs";
import ArrowLeft from "../../../../../../assets/arrow-left.svg";
import ArrowRight from "../../../../../../assets/arrow-right.svg";
import { CustomTextFiled } from "../../../../../CustomTextFiled";
import Trash from "../../../../../../assets/trash.svg";
import Add from "../../../../../../assets/add.svg";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { PaperProps } from "@mui/material/Paper/Paper";
function PaperComponent(props: PaperProps) {
  return (
    <div style={{ padding: "4rem" }}>
      <Paper sx={{ padding: "4rem", borderRadius: "28px" }} {...props} />
    </div>
  );
}

function VariousOutlayForm() {
  const dispatch = useAppDispatch();
  const CalculatorState = useAppSelector(selectCalculatorState);
  const { total, VariousOutlayData } = CalculatorState;
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToTheBeginning = () => {
    setOpen(false);
    dispatch(setActiveOutlaysInnerStep(0));
  };
  const handleNextStep = () => {
    dispatch(allowNextStep(2));
    dispatch(setActiveStep(2));
  };

  const temp = VariousOutlayData.items.filter(
    (item: { amount: number }) => item.amount > 0
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      variuos: [
        ...temp,
        {
          name: "",
          amount: null,
        },
      ],
    },
    mode: "onBlur",
  });
  const { fields, append, remove } = useFieldArray({
    name: "variuos",
    control,
  });
  const onSubmit = (data: any) => {
    delete data.variousTotal;
    let { variuos } = data;
    const sumWithInitialValue = () =>
      variuos.reduce((acc: any, curr: any) => {
        return Number(acc) + Number(curr.amount);
      }, 0);
    if (!isNaN(sumWithInitialValue())) {
      dispatch(setTotalVarious(sumWithInitialValue()));
      const temp = data.variuos.filter(
        (item: { amount: number }) => item.amount > 0
      );
      dispatch(setVariousData(temp));
      dispatch(setTotal());
    }
    if (isNaN(sumWithInitialValue())) {
      dispatch(setVariousData(data.variuos));
      dispatch(setTotalVarious(0));
      dispatch(setTotal());
    }
    if (
      total + Number(!!sumWithInitialValue()) < 0 ||
      total + Number(sumWithInitialValue()) === 0
    ) {
      handleClickOpen();
    }
    if (total + sumWithInitialValue() > 0) {
      handleNextStep();
    }
  };

  const handleBackClick = () => {
    if (CalculatorState.allowedSteps > 0) {
      if (
        CalculatorState.outlaysInnerAllowedSteps >= 0 &&
        CalculatorState.outlaysInnerActiveStep === 0
      ) {
        dispatch(setActiveStep(0));
      }
      if (
        CalculatorState.outlaysInnerAllowedSteps > 0 &&
        CalculatorState.outlaysInnerActiveStep > 0
      ) {
        dispatch(
          setActiveOutlaysInnerStep(CalculatorState.outlaysInnerActiveStep - 1)
        );
      }
    }
  };

  return (
    <div className={styles.wrapper} style={{ width: "100%" }}>
      <Grow timeout={{ enter: 900, exit: 100 }} in={true}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <br />
          <h3
            style={{
              textAlign: "center",
              fontWeight: "700",
              width: "100%",
              fontSize: "1.75rem",

              padding: 0,
            }}
          >
            שונות
          </h3>
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <section className={styles.field_grid} key={field.id}>
                  <Controller
                    {...field}
                    name={`variuos.${index}.name`}
                    control={control}
                    render={({ field }) => (
                      <CustomTextFiled
                        sx={{ marginBottom: "1rem", maxWidth: "500px" }}
                        id="filled-basic"
                        label="שם הוצאה"
                        variant="filled"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    {...field}
                    name={`variuos.${index}.amount`}
                    control={control}
                    render={({ field }) => (
                      <CustomTextFiled
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                          disableUnderline: true,
                        }}
                        sx={{ marginBottom: "1rem", maxWidth: "500px" }}
                        id="filled-basic"
                        variant="filled"
                        {...field}
                        label="הסכום"
                      />
                    )}
                  />
                  <div className={styles.action_wrapper}>
                    <Button type="button" onClick={() => remove(index)}>
                      <img
                        src={Trash}
                        alt="remove"
                        style={{ marginBottom: "0.25rem" }}
                      />
                    </Button>
                    <Button
                      type="button"
                      onClick={() =>
                        append({
                          name: "",
                          amount: undefined,
                        })
                      }
                    >
                      <img
                        src={Add}
                        alt="add"
                        style={{ marginBottom: "0.25rem" }}
                      />
                    </Button>
                  </div>
                </section>
              </div>
            );
          })}
          <br />
          <div
            style={{
              display: "grid",
              placeItems: "center",
              alignItems: "center",
              gridTemplateColumns: "auto auto",
              alignContent: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <button onClick={handleBackClick} className="back">
              <img
                src={ArrowRight}
                alt="arrow"
                style={{ marginBottom: "0.25rem" }}
              />
              <Typography
                sx={{ fontSize: "1.2rem", color: "black" }}
                display="block"
                gutterBottom
              >
                הקודם
              </Typography>
            </button>
            <button className="btn next" type="submit">
              <Typography
                sx={{ fontSize: "1.2rem" }}
                display="block"
                gutterBottom
              >
                הבא
              </Typography>
              <img
                src={ArrowLeft}
                alt="arrow"
                style={{ marginBottom: "0.25rem" }}
              />
            </button>
          </div>
        </form>
      </Grow>
      <br />
      <Dialog
        PaperComponent={PaperComponent}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        sx={{ padding: "4rem" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          בטוח שאין לך אף הוצאה קבועה?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            אם ישנן הוצאות קבועות לחץ על לא בטוח והזן בשדות
            <br />
            אם אין הוצאות קבועות לחץ על הבא
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn_action_no" onClick={handleToTheBeginning}>
            לא בטוח
          </button>
          <button className="btn_action_yes" onClick={handleNextStep} autoFocus>
            בטוח
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VariousOutlayForm;
