import React, { useCallback } from "react";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import IconButton from "@mui/material/IconButton";
import { selectCalculatorState } from "../calculatorSlice";
import { useAppSelector } from "../../../app/hooks";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function Tips() {
  const CalculatorState = useAppSelector(selectCalculatorState);
  const { outlaysInnerActiveStep } = CalculatorState;
  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const handleClick =
    (
      Transition: React.ComponentType<
        TransitionProps & {
          children: React.ReactElement<any, any>;
        }
      >
    ) =>
    () => {
      setState({
        open: true,
        Transition,
      });
    };
  const handleClose = useCallback(() => {
    setState({
      ...state,
      open: false,
    });
  }, [state]);
  React.useEffect(() => {
    setTimeout(() => {
      handleClick(SlideTransition)();
    }, 2500);
    setTimeout(() => {
      handleClose();
    }, 8500);
  }, [outlaysInnerActiveStep]);
  return (
    <div className="tip_grid">
      <div>
        <Snackbar
          open={state.open}
          onClose={handleClose}
          TransitionComponent={state.Transition}
          message={`טיפ: חיסכון הוא קירטי בשביל להוציא לפועל תוכניות גדולות, לא כדאי לוותר עליו. כדי לדעת עוד על סכום הכסף שעליכם לחסוך בשביל התוכניות שלכם, היכנסו ליחידת "תכנון עתיד"`}
          key={state.Transition.name}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          action={
            <React.Fragment>
              <IconButton
                onClick={handleClose}
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
              >
                <TipsAndUpdatesIcon sx={{ margin: "0 0 0 0" }} />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
      <br />
    </div>
  );
}

export default Tips;
