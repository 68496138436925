import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import calculatorReducer from '../features/calculator/calculatorSlice';

import logger from 'redux-logger'
import { loadState } from "./browser-storage";

const rootReducer = combineReducers({
  calculator: calculatorReducer,
})

const store = configureStore({
  devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  reducer: rootReducer,
  preloadedState: loadState(),

});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
