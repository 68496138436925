import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import * as serviceWorker from "./serviceWorker";
import theme from "./app/theme";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { debounce } from "debounce";
import { saveState } from "./app/browser-storage";

const container = document.getElementById("app");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
store.subscribe(
  debounce(() => {
    saveState(store.getState());
  }, 1000)
);

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CacheProvider value={cacheRtl}>
          <CssBaseline />
          <App />
        </CacheProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

serviceWorker.unregister();
