import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
export const CustomTextFiled = styled(TextField)(({ theme }) => ({
  borderRadius: "16px",
  background: "white",
  "& .MuiFilledInput-root": {
    borderRadius: 18,
    background: "white",
    textAlign: "center",
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  "& .MuiFilledInput-root::placeholder": {
    color: "red",
    textAlign: "center",
  },
  "& .MuiInputBase-input": {
    borderRadius: 18,
    background: "white",
    textAlign: "center",
  },
  "& .MuiFilledInput-root:before": {
    borderBottom: `none`,
    textAlign: "center",
  },
  "& .MuiFilledInput-root:after": {
    borderBottom: `none`,
    textAlign: "center",
  },
  "& .MuiFilledInput-input:hover": {
    textDecoration: "none",
  },
}));
