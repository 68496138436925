import { Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../app/hooks";
import { selectCalculatorState } from "../../../../calculatorSlice";
import CommunicationForm from "./components/Forms/CommunicationForm";
import HousingForm from "./components/Forms/HousingForm";
import TransportForm from "./components/Forms/TransportForm";
import HealthForm from "./components/Forms/HealthForm";
import OutlaysInnerStepper from "./components/OutlaysInnerStepper";
import EducationForm from "./components/Forms/EducationForm";
import VariousOutlayForm from "./components/Forms/VariousOutlayForm";
import OverdraftProcess from "./components/OverdraftProcess";
import WaveLogo from "../../../../../../wave_logo.svg";

const MultiStepFoem = () => {
  const CalculatorState = useAppSelector(selectCalculatorState);
  return (
    <Grid
      sx={{
        textAlign: "center",
        display: "grid",
        placeItems: "center",
        width: "100%",
      }}
      container
    >
      <div className="outlay_back_button"></div>

      <Grid item>
        <Typography variant="h5" component="h1" gutterBottom>
          <strong>הוצאות קבועות:</strong>
        </Typography>
      </Grid>
      <Grid
        sx={{ textAlign: "right", display: "grid", placeItems: "center" }}
        item
      >
        <Typography variant="subtitle1" component="h3" gutterBottom>
          כמה כסף יוצא לך להוציא כל חודש?
          <ul>
            <li>הוצאה קבועה היא כל תשלום שהתחייבנו אליו מראש.</li>
            <li>אם זה כסף שיוצא מחשבונך פעם בשנה ניתן לחלק אותו ב-12.</li>
            <li>
              יש כאן סעיף שבכלל לא רלוונטי למקרה שלך? אפשר להשאיר אותו ריק או
              לכתוב 0.
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item sx={{ minHeight: "600px" }}>
        <div
          style={{
            margin: "0 auto",
            display: "grid",
            placeItems: "center",
            justifyContent: "center",
            maxWidth: "600px",
          }}
        >
          <div>
            <OutlaysInnerStepper />
          </div>
        </div>
        {CalculatorState.outlaysInnerActiveStep === 0 && <HousingForm />}
        {CalculatorState.outlaysInnerActiveStep === 1 && <TransportForm />}
        {CalculatorState.outlaysInnerActiveStep === 2 && <CommunicationForm />}
        {CalculatorState.outlaysInnerActiveStep === 3 && <HealthForm />}
        {CalculatorState.outlaysInnerActiveStep === 4 && <EducationForm />}
        {CalculatorState.outlaysInnerActiveStep === 5 && <VariousOutlayForm />}
      </Grid>
    </Grid>
  );
};

const OutlaysStep = () => {
  const { toSpear, forceContinue } = useAppSelector(selectCalculatorState);
  if (Number(toSpear) < Number(0) && !forceContinue) {
    return (
      <>
        <OverdraftProcess />
        <footer
          style={{
            width: "100%",
            transform: "translate(0px,20%)",
          }}
        >
          <p>
            מחשבון זה אינו מהווה תחליף לייעוץ פיננסי זה או אחר והינו באחריות
            המשתמשים בלבד!
          </p>
          <img
            style={{ marginTop: "3rem", marginBottom: "3rem" }}
            src={WaveLogo}
            alt="wave logo"
          />
        </footer>
      </>
    );
  }
  return (
    <>
      <MultiStepFoem />
      <footer
        style={{
          width: "100%",
          transform: "translate(0px,20%)",
        }}
      >
        <p>
          מחשבון זה אינו מהווה תחליף לייעוץ פיננסי זה או אחר והינו באחריות
          המשתמשים בלבד!
        </p>
        <img
          style={{ marginTop: "3rem", marginBottom: "3rem" }}
          src={WaveLogo}
          alt="wave logo"
        />
      </footer>
    </>
  );
};

export default OutlaysStep;
