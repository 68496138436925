import * as React from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../app/hooks";
import {
  setFinished,
  selectActiveStep,
  setSavings,
  selectCalculatorState,
  setActiveStep,
  setActiveOutlaysInnerStep,
} from "../../../../../calculatorSlice";
import {
  Grid,
  Box,
  Typography,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import NumberFormat from "react-number-format";
import { NumberFormatCustom } from "../../../../FormattedInputs";
import Finished from "./Finished";
import ArrowLeft from "../../../../../assets/arrow-left.svg";
import ArrowRight from "../../../../../assets/arrow-right.svg";
import { CustomTextFiled } from "../../../../CustomTextFiled";
import { PaperProps } from "@mui/material/Paper/Paper";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import Tips from "../../../../Tips";
function PaperComponent(props: PaperProps) {
  return (
    <div style={{ padding: "4rem" }}>
      <Paper sx={{ padding: "4rem", borderRadius: "28px" }} {...props} />
    </div>
  );
}

function Savings() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const CalculatorState = useAppSelector(selectCalculatorState);
  const { savings, toSpear, finished } = CalculatorState;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<any>();
  React.useEffect(() => {
    dispatch(setFinished(false));
  }, []);
  const handleBackClick = () => {
    if (CalculatorState.allowedSteps === 2) {
      if (CalculatorState.activeStep === 2) {
        dispatch(setFinished(false));
        dispatch(setActiveOutlaysInnerStep(0));
        dispatch(setActiveStep(CalculatorState.activeStep - 1));
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToTheBeginning = () => {
    setOpen(false);
  };
  const handleNextStep = () => {
    dispatch(setFinished(true));
  };

  const onSubmit: SubmitHandler<any> = (data) => {
    const savingsValue = getValues("savings");
    console.log(typeof savingsValue, "savings");
    dispatch(setSavings(Number(data.savings)));
    if (Number(savingsValue) === 0 || typeof savingsValue === undefined) {
      handleClickOpen();
    }
    if (Number(savingsValue) > 0 && Number(savingsValue) !== undefined) {
      handleNextStep();
    }
  };
  if (finished) {
    return <Finished />;
  }
  return (
    <>
      <Grid
        sx={{ textAlign: "center", display: "grid", placeItems: "center" }}
        container
      >
        <br />
        <Grid sm={12} item>
          <Typography variant="h6" component="h1" gutterBottom>
            כל הכבוד שהגעת עד לכאן
          </Typography>
          <Typography variant="body1" component="h3" gutterBottom>
            רק עוד שלב אחד וסיימנו...
          </Typography>
        </Grid>
        <Grid sm={12} item></Grid>
        <Grid item>
          <Box
            sx={{
              margin: "1rem",
            }}
          ></Box>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h2" gutterBottom>
            משקיעים בעתיד - חיסכון
          </Typography>
          <Typography variant="h54" component="h1" gutterBottom>
            עד עכשיו נשארו לך{" "}
            <span style={{ color: "grey" }}>
              <NumberFormat
                value={toSpear}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₪"}
              />
            </span>{" "}
            שקלים
          </Typography>
          <Typography variant="h4" component="h3" gutterBottom>
            כמה כסף מבחינך אפשר לשים בצד בכל חודש?
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom>
            (למקרה חירום, לטיול הגדול, לימודים, דירה ועוד...)
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <Controller
            defaultValue={savings === 0 ? "" : savings}
            name="savings"
            control={control}
            rules={{ max: toSpear }}
            render={({ field }) => (
              <CustomTextFiled
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: { min: 0, max: toSpear },
                }}
                {...field}
                error={!!errors.savings}
                helperText={
                  errors.savings && "סכום החיסכון גדול משנשאר לך עד כה"
                }
                sx={{ marginBottom: "1rem", width: "80vw", maxWidth: "500px" }}
                id="filled-basic"
                label="כאן לכתוב את הסכום במספרים בלבד"
                variant="filled"
              />
            )}
          />
          <br />
          <br />
          <hr />
          <Grid item>
            <Grid item>
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  gridTemplateColumns: "auto auto",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <button onClick={handleBackClick} className="back">
                  <img
                    src={ArrowRight}
                    alt="arrow"
                    style={{ marginBottom: "0.25rem" }}
                  />
                  <Typography
                    sx={{ fontSize: "1.2rem", color: "black" }}
                    gutterBottom
                  >
                    הקודם
                  </Typography>
                </button>
                <button className="btn next" type="submit">
                  <Typography sx={{ fontSize: "1.2rem" }} gutterBottom>
                    הבא
                  </Typography>
                  <img
                    src={ArrowLeft}
                    alt="arrow"
                    style={{ marginBottom: "0.25rem" }}
                  />
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
        <Grid item>
          <Tips />
        </Grid>
        <Dialog
          PaperComponent={PaperComponent}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          sx={{ padding: "4rem" }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            חיסכון זה דבר קריטי
            <br />
            בטוח לא לשים כסף בצד?
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <button className="btn_action_no" onClick={handleToTheBeginning}>
              לא בטוח
            </button>
            <button
              className="btn_action_yes"
              onClick={handleNextStep}
              autoFocus
            >
              בטוח
            </button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}

export default Savings;
