import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  selectActiveStep,
  setActiveStep,
  selectAllowedSteps,
  selectCalculatorState,
  setFinished,
} from "../../calculatorSlice";
import theme from "../../../../app/theme";
import Check from "@mui/icons-material/Check";
import { StepIconProps } from "@mui/material/StepIcon";
const steps = [
  { label: "הכנסות", step: 0 },
  { label: "הוצאות", step: 1 },
  { label: "חיסכון", step: 2 },
];
export default function StepperComponent() {
  const activeStep = useAppSelector(selectActiveStep);
  const allowedSteps = useAppSelector(selectAllowedSteps);
  const { finished, started } = useAppSelector(selectCalculatorState);
  const dispatch = useAppDispatch();
  const QontoConnector = styled(StepConnector)(({ theme: any }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 15,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#EE2F60",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#EE2F60",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EE2F60",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "grey",
      display: "flex",
      height: "100%",
      borderRadius: "100%",
      background: " #fafafa 0% 0 % no-repeat padding-box",
      boxShadow: "0px 3px 6px #0000004F",
      border: "4px solid #FFFFFF",
      opacity: 1,
      alignItems: "center",

      ...(ownerState.active && {
        transform: "scale(1)",
        animation: "pulse-red 2s infinite",
        "& .QontoStepIcon-circle": {
          width: 24,
          height: 24,
          borderRadius: "50%",
          backgroundColor: "#EE2F60",
        },
      }),

      "& .QontoStepIcon-completedIcon": {
        color: "white",
        zIndex: 1,
        fontSize: 18,
        backgroundColor: "#EE2F60",
        borderRadius: "50%",
        width: 24,
        height: 24,
      },
      "& .QontoStepIcon-circle": {
        width: 24,
        height: 24,
        backgroundColor: "#BCBCBC",

        borderRadius: "50%",
      },
    })
  );
  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  return (
    <div style={{ width: "30vw", minWidth: "320px", margin: "0 auto" }}>
      <Box sx={{ width: "100%" }}>
        <Stepper
          sx={{ width: "100%" }}
          alternativeLabel
          activeStep={
            !started ? activeStep + 3 : !finished ? activeStep : activeStep + 1
          }
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step
              onClick={(e) => {
                if (allowedSteps > label.step || allowedSteps === label.step) {
                  dispatch(setActiveStep(label.step));
                  dispatch(setFinished(false));
                }
              }}
              key={label.step}
            >
              <StepLabel StepIconComponent={QontoStepIcon}>
                {label.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}
