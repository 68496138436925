import { Grid, Box } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectCalculatorState,
  setActiveStep,
  setFinished,
  setStarted,
} from "./calculatorSlice";
import OutlaysStep from "./components/Stepper/Steps/OutlaysStep";
import SummaryStep from "./components/Stepper/Steps/SummeryStep/";
import OpenScreen from "./components/OpenScreen";
import IncomeStep from "./components/Stepper/Steps/IncomeStep";
import styles from "./Calculator.module.css";
import StepperComponent from "./components/Stepper/StepperComponent";
import Logo from "./assets/logo_lev.svg";
import Flach from "./assets/flach.png";
import NumberFormat from "react-number-format";
import Footer from "./components/Footer";
import ActionButtons from "./components/ActionButtons";
export function Calculator() {
  const dispatch = useAppDispatch();
  const { activeStep, started, total } = useAppSelector(selectCalculatorState);
  if (!started) {
    return <OpenScreen />;
  }
  return (
    <Grid container>
      <div className={styles.calc_grid}>
        <br />

        <Grid item xs={12}>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <img
                  onClick={() => dispatch(setStarted(false))}
                  style={{ maxWidth: "400px", width: "80%" }}
                  alt="logo"
                  src={Logo}
                />
              </Grid>
              <Grid item xs={12}>
                <h1>מחשבון התקציב שלך</h1>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    width: "50vw",
                    margin: "0 auto",
                    display: "grid",
                    placeContent: "center",
                    placeItems: "center",
                  }}
                >
                  <StepperComponent />
                  <br />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.card}>
                  {activeStep === 1 && (
                    <div>
                      <img
                        style={{ marginTop: "-0.9rem" }}
                        src={Flach}
                        alt="logo"
                      />
                      <p style={{ marginTop: "-2.8rem", color: "white" }}>
                        סך כל הוצאות חודשיות:{" "}
                        <span style={{ fontSize: "1.25rem" }}>
                          <NumberFormat
                            decimalScale={2}
                            value={total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₪"}
                          />
                        </span>
                      </p>
                    </div>
                  )}
                  {activeStep === 0 && <IncomeStep />}
                  {activeStep === 1 && <OutlaysStep />}
                  {activeStep === 2 && <SummaryStep />}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <ActionButtons />
        <Footer />
      </div>
    </Grid>
  );
}
